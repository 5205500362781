import React, { Component } from "react"
import FormArea from "./FormArea"
import SvgSicap from "../../assets/svgComponents/seap.svg"


class HeroSection extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { svgTitle } = this.props
    return (
      <div className="page-title-area seap-hero">
        <SvgSicap className={svgTitle} />
        <div className="container">
          <div className="page-title-content text-left">
            <h1 className="mb-4">Achiziții SEAP Webhosting</h1>
            <div className="row">
              <div className="col-md-12 col-lg-9 col-12">
                <p>SiteBunker este înscris în sistemul electronic de achiziții publice (SEAP) unde pot fi
                  achiziționate oricare dintre serviciile de webhosting oferite.</p>
                <p>Pentru detalii legate de achiziții webhosting prin SEAP ne poți contacta prin intermediul
                  formularului de mai jos.</p>
              </div>
            </div>
          </div>
        </div>

        <FormArea />
      </div>

    )

  }
}

export default HeroSection
