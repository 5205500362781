import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import BlueInformation from "../components/AchizitiiSEAP/BlueInformation"
import ClientFeedback from "../components/Index/ClientFeedback"
import HeroSection from "../components/AchizitiiSEAP/HeroSection"

import "/src/assets/css/AchizitiiSEAP/seap.css"
import SEO from "../components/App/SEO"

const AchizitiiSeap = () => {
  return (
    <Layout>
      <SEO title="Achiziții SEAP - Sitebunker - Webhosting performant"
           description="Din 2006 SiteBunker oferă servicii premium de webhosting, domenii și servere dedicate sau VPS. Suntem înscriși în sistemul de achiziții SEAP. "
      />
      <Navbar />
      <HeroSection svgTitle={"seap"} />
      <BlueInformation />

      <div className='pt-mobile'>
        <ClientFeedback
          title={"Citește părerea clienților noștri despre serviciile de "}
          bold={"webhosting SiteBunker"} />
      </div>
      <Footer />
    </Layout>
  )
}

export default AchizitiiSeap
