import React, { Component } from "react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import emailjs from "emailjs-com"
import FormConfirmMessages from "../Common/FormConfirmMessages"

class FormArea extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formRequestSend: false,
      responseMsg: false
    }
  }

  sendSEAP(formData) {
    this.setState({ formRequestSend: false })
    emailjs.send("service_m368v5a", "template_f1dtz99", formData, "user_QzYlAeR2qOAc7gQDDORKV")
      .then((result) => {
        this.setState({ formRequestSend: true })
        this.setState({ responseMsg: true })
      }, (error) => {
        this.setState({ formRequestSend: true })
        this.setState({ responseMsg: false })
      })
  }


  render() {
    const {formRequestSend, responseMsg} = this.state
    const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validate = Yup.object({
      name: Yup.string().required("Câmpul este necesar"),
      email: Yup.string().email("Emailul nu este valid").required("Câmpul este necesar"),
      phoneNumber: Yup.string().matches(phoneRegEx, "Numarul nu este valid").min(10, "Numarul nu este valid").max(10, "Numarul nu este valid").required("Câmpul este necesar"),
      info: Yup.string().min(10, "Minim 10 caractere!").required("Câmpul este necesar")
    })


    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 offset-lg-6 col-md-12 col-12">
            <FormConfirmMessages formRequestSend={formRequestSend} responseMsg={responseMsg} />
            <div className="contact-form ml-0">
              <Formik initialValues={{ name: "", email: "", phoneNumber: "", info: "" }} validationSchema={validate}
                      onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        this.sendSEAP(data)
                        axios.post("https://sitebunker-app.herokuapp.com/seaps", data).then(response => {
                        }, (error) => console.log(error))
                        setSubmitting(false)
                        resetForm()
                      }}>
                {({ isSubmitting }) => (
                  <Form id="contactForm">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <Field name="name" type="text" placeholder="Nume" className="form-control" />
                          <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="name" />
                        </div>

                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <Field name="email" type="email" placeholder="Email" className="form-control" />
                          <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="email" />
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <Field name="phoneNumber" type="text" placeholder="Număr telefon"
                                 className="form-control" />
                          <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="phoneNumber" />
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <Field name="info" placeholder="Informații" className="form-control"
                               rows="5" as="textarea" />
                        <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="info" />
                      </div>
                      <div className="col-12 mt-3">
                        <button disabled={isSubmitting} type="submit" className="form-btn">
                          Trimite
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FormArea
