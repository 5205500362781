import React from "react"

const BlueInformation = () => {
  return (
    <div className="blue-section main-blue-bg text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="h3-title text-white font-weight-bold mb-3">Află detalii despre achiziții SEAP Webhosting</h3>
            <p className="p-medium-small">
              Este simplu să faci orice achiziție de webhosting prin SEAP la SiteBunker. Este necesar doar să ne transmiți
              denumirea serviciilor (webhosting/achiziționare domenii/servere dedicate/servere vps) de care ești interesat
              și în baza cererii tale îți transmite oferta comercială. După acceptarea ofertei, adaugăm serviciile de webhosting
              de care ești interesat în catalogul SEAP, îți comunicăm denumirea şi transmitem codul serviciilor publicate în catalog.
              Tot ce mai trebuie să faci este să iniţiezi oficial achiziţia, iar SiteBunker deliberează serviciile.
            </p>
            <p className="p-medium-small">După atribuirea cumpărării directe, vei beneficia imediat de serviciul achiziționat.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BlueInformation
